import React, { ReactNode, useRef, useState, useEffect } from "react";
import "./Custom.scss";
import "./../UtilSass/Util.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  Label,
  Table,
  Loader,
  Button,
  Dimmer,
  Modal,
  Image,
  Icon,
  Accordion,
  Segment,
  Progress,
  Pagination,
  Input,
  Form,
  TextArea,
  Dropdown,
  Checkbox,
  Radio,
  InputProps,
  DropdownProps,
  TextAreaProps,
  CheckboxProps,
  Divider,
  Card,
  Header,
  List,
  Message,
  Tab,
  Menu,
  Popup,
} from "semantic-ui-react";
import { levelColorCodes, levelColorCodeArray, limitOptions, ROUTE_PATH } from "../../static";
import {
  replaceIdKeyFromRoutePath,
  textOverflow,
  getDateTimeString,
  numberToBoolean,
  undefinedToBoolean,
  getDurationString,
  undefinedToNumber,
  isDisabledTest,
  getLevelColorClassName,
  getOnlyNumber,
  calcDayAgo,
  undefinedToString,
  getContentPagePath,
  getThemePagePath,
  getLength,
} from "../../util/common.util";
import {
  customBreadcrumbProps,
  genericBreadcrumbProps,
  themeStatusLabelProps,
  reportStatusLabelProps,
  testStatusLabelProps,
  contentsStatusLabelProps,
  customTableProps,
  customTableHeaderType,
  customTableRowProps,
  customTableHeaderProps,
  customLoaderProps,
  customModalProps,
  customConfirmModalProps,
  customInputModalProps,
  customSingleCheckBoxProps,
  questionBoxStatusLabelProps,
  impressionStatusProps,
  themeProgressProps,
  tablePagenationProps,
  customProgressProps,
  customInputTextProps,
  customInputNumberProps,
  customReadOnlyTextProps,
  customInputDatePickerProps,
  customDropdownProps,
  customMultipleDropdownProps,
  customRadioProps,
  customRadioChoiceType,
  customRadioItemProps,
  ValidStatusLabelProps,
  mediaUploadStatusLabelProps,
  bulkUserHistoryStatusLabelProps,
  levelabelProps,
  categoryTestPassLabelProps,
  statisticCountProps,
  statisticLevelProps,
  statisticCustomUnittProps,
  themeItemProps,
  progressCardProps,
  childrenProps,
  CustomThemeReportItemProps,
  reportStatus,
  customContentsItemProps,
  customHeaderWithAccountInfomationProps,
  customHeaderWithUserIdProps,
  customTestItemProps,
  customNotFoundPageMessage,
  customCareerItemProps,
  commonContentsItemProps,
  customSimpleLayoutGrouingContentsListProps,
  selectedAnswerType,
  apiLoadTestItem,
  apiLoadTestTemplateDtl,
  apiLoadTheme,
  apiLoadContents,
  apiLoadMypageAccountOriginalTheme,
  customSimpleLayoutGrouingThemeProps,
  apiLoadMypageAccountOriginalContents,
  apiLoadMypageFreeContentsAndTutorialTheme,
  apiLoadMypageFreeContentsItems,
  apiLoadMypageLegalCurriculumTheme,
  apiTheme,
  idType,
  deviceType,
  contentsStatus,
  customSubMenuProps,
  customMultiDropdownProps,
  customProgressWithWalkIconProps,
  customProgressIconAndMedalProps,
  customLimitDropdownProps,
  UserStatusLabelProps,
  customBreadcrumbLoadByApiProps,
  apiLoadBreadcrumb,
  careerType,
  inquiryStatus,
  packageStatusLabelProps,
  accountPlanType,
} from "../../types";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import DatePicker, { registerLocale } from "react-datepicker";
registerLocale("ja", ja);
import { MdDirectionsWalk, MdEmojiPeople, MdFlag } from "react-icons/md";
import { Cell, Pie, PieChart, Bar, BarChart, LabelList, XAxis, Line } from "recharts";
import {
  useLoadAccountUnconfirmedReports,
  useUpdateReportStatusConfirmdApi,
  useUpdateReportStatusUnconfirmdApi,
  useLoadBreadcrumbApi,
} from "../../api/api.hooks";
import { useBadgeDispatch, setUnconfirmedReportCount } from "../../contexts/BadgeContext";
import { useUserContext } from "../../contexts/UserContext";
import iconUp from "../../assets/images/icon_up.svg";
import progressMedalCurrent from "../../assets/images/progress_medal_current.svg";
import progressMedal from "../../assets/images/progress_medal.svg";
import LegalReportForm from "../LegalReportForm/LegalReportForm";
import correctIcon from "../../assets/images/correct_icon.svg";
import incorrectIcon from "../../assets/images/incorrect_icon.svg";
import completionMedal from "../../assets/images/completion_medal.svg";
import { IoWarning } from "react-icons/io5";
import menuPackageManagement from "../../assets/images/menu_package_management.svg";

export const CustomBreadcrumb = (props: customBreadcrumbProps) => {
  const { contextData: user } = useUserContext();
  const isCategoryValid = (current: string) => {
    switch (current) {
      case "theme":
      case "contents":
      case "test":
      case "question":
        return true;
      case "home":
      case "category":
        return false;
      default:
        return false;
    }
  };
  const isThemeValid = (current: string) => {
    switch (current) {
      case "contents":
      case "test":
      case "question":
        return true;
      case "home":
      case "category":
      case "theme":
        return false;
      default:
        return false;
    }
  };
  const isContentsValid = (current: string) => {
    switch (current) {
      case "test":
        return true;
      case "home":
      case "category":
      case "theme":
      case "contents":
      case "question":
        return false;
      default:
        return false;
    }
  };
  return (
    <div className="common_breadcrumb">
      {props.home && user.type !== "CORPORATION_ADMIN" ? (
        <Link to={ROUTE_PATH.MYPAGE}>マイページ</Link>
      ) : (
        <Link to={ROUTE_PATH.INCLUDED_CONTENTS}>収録コンテンツ一覧</Link>
      )}
      {isCategoryValid(props.current) && (
        <>
          {props.home && <span className="breadcrumb_divider"> / </span>}
          <Link
            to={replaceIdKeyFromRoutePath(
              props.ids,
              props.original ? ROUTE_PATH.CATEGORY_ORIGINAL : ROUTE_PATH.CATEGORY
            )}
          >
            {props.titles.category}
          </Link>
        </>
      )}
      {isThemeValid(props.current) && (
        <>
          <span className="breadcrumb_divider"> / </span>
          <Link
            to={replaceIdKeyFromRoutePath(props.ids, props.original ? ROUTE_PATH.THEME_ORIGINAL : ROUTE_PATH.THEME)}
          >
            {props.titles.theme}
          </Link>
        </>
      )}
      {isContentsValid(props.current) && (
        <>
          <span className="breadcrumb_divider"> / </span>
          <Link
            to={replaceIdKeyFromRoutePath(
              props.ids,
              props.original ? ROUTE_PATH.CONTENTS_ORIGINAL : ROUTE_PATH.CONTENTS
            )}
          >
            {props.titles.contents}
          </Link>
        </>
      )}
    </div>
  );
};

export const CustomBreadcrumbLoadByApi = (props: customBreadcrumbLoadByApiProps) => {
  const [breadcrumb, setBreadcrumb] = useState<apiLoadBreadcrumb>();
  const { isLoading, callLoadBreadcrumbApi } = useLoadBreadcrumbApi();
  const { contextData: user } = useUserContext();

  const getBreadcrumb = async (original: boolean, themeId: number, contentsId?: number) => {
    const { isSuccess, result } = await callLoadBreadcrumbApi(original, themeId, contentsId);
    isSuccess && result && setBreadcrumb(result);
  };

  useEffect(() => {
    getBreadcrumb(props.original, props.themeId, props.contentsId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);
  if (props.visible === false) {
    <> </>;
  }

  if (isLoading) {
    return (
      <div className="common_breadcrumb">
        <Loader active inline size="mini" />
      </div>
    );
  } else {
    return (
      <div className="common_breadcrumb">
        <Link to={ROUTE_PATH.INCLUDED_CONTENTS}>収録コンテンツ</Link>
        <span className="breadcrumb_divider"> / </span>
        {breadcrumb?.breadcrumbType === "CATEGORY" && (
          <Link
            to={
              props.original
                ? replaceIdKeyFromRoutePath({ categoryId: breadcrumb.category.id }, ROUTE_PATH.CATEGORY_ORIGINAL)
                : replaceIdKeyFromRoutePath({ categoryId: breadcrumb.category.id }, ROUTE_PATH.CATEGORY)
            }
          >
            {breadcrumb?.category.name}
          </Link>
        )}
        {breadcrumb?.breadcrumbType === "CAREER_THEME" && (
          <Link
            to={replaceIdKeyFromRoutePath(
              {
                level: breadcrumb.career.level,
                careerType: user.careerType ? user.careerType.toLocaleLowerCase() : "kaigo",
              },
              ROUTE_PATH.CAREER
            )}
          >
            {breadcrumb?.career.name}
          </Link>
        )}
        {props.useTheme && (
          <>
            <span className="breadcrumb_divider"> / </span>
            <Link
              to={
                props.original
                  ? replaceIdKeyFromRoutePath({ themeId: breadcrumb?.theme.id }, ROUTE_PATH.THEME_ORIGINAL)
                  : replaceIdKeyFromRoutePath({ themeId: breadcrumb?.theme.id }, ROUTE_PATH.THEME)
              }
            >
              {breadcrumb?.theme.name}
            </Link>
          </>
        )}
        {props.useContents && (
          <>
            <span className="breadcrumb_divider"> / </span>
            <Link
              to={
                props.original
                  ? replaceIdKeyFromRoutePath({ contentsId: breadcrumb?.contents.id }, ROUTE_PATH.CONTENTS_ORIGINAL)
                  : replaceIdKeyFromRoutePath({ contentsId: breadcrumb?.contents.id }, ROUTE_PATH.CONTENTS)
              }
            >
              {breadcrumb?.theme.name}
            </Link>
          </>
        )}
      </div>
    );
  }
};

export const GenericBreadcrumb = (props: genericBreadcrumbProps) => {
  return (
    <div className="common_breadcrumb">
      {props.links.map((item, index) => {
        return (
          <React.Fragment key={`${item.code}_${index}`}>
            {index > 0 && <span className="breadcrumb_divider"> / </span>}
            <Link to={item.url}>{item.label}</Link>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export const ThemeStatusLabel = (props: themeStatusLabelProps) => {
  const circular = (() => {
    if (props.circular) return true;
    return false;
  })();
  switch (props.status) {
    case "NOT_STUDY":
      return <Label circular={circular} content="未受講" size={props.size} color="grey" />;
    case "STUDYING":
      return <Label circular={circular} content="受講中" size={props.size} color="teal" />;
    case "STUDIED":
      return <Label circular={circular} content="受講済" size={props.size} color="blue" />;
    default:
      return <></>;
  }
};

export const AccountPlanLabel = (props: { plan: accountPlanType }) => {
  switch (props.plan) {
    case "FULL":
      return <Label content="フル" color="blue" size="small" />;
    case "LIGHT":
      return <Label content="ライト" color="teal" size="small" />;
    case "PACKAGE":
      return <Label content="パッケージのみ" color="grey" size="small" />;
    default:
      return <></>;
  }
};

export const ReportStatusLabel = (props: reportStatusLabelProps) => {
  const circular = (() => {
    if (props.circular) return true;
    return false;
  })();
  switch (props.reportFinished) {
    case true:
      return <Label circular={circular} content="感想レポート：提出済" size={props.size} color="blue" />;
    case false:
      return <Label circular={circular} content="感想レポート：未提出" size={props.size} color="orange" />;
    default:
      return <></>;
  }
};

export const TestStatusLabel = (props: testStatusLabelProps) => {
  const circular = (() => {
    if (props.circular) return true;
    return false;
  })();
  switch (props.status) {
    case "UNTEST":
      return <Label circular={circular} content={"未テスト"} size={props.size} color="grey" />;
    case "TESTED":
      if (props.testPassed) {
        return <Label circular={circular} content={"テスト済"} size={props.size} color="green" />;
      } else {
        return <Label circular={circular} content={"テスト不合格"} size={props.size} color="pink" />;
      }
    default:
      return <></>;
  }
};

export const TestStatusNewLabel = (props: testStatusLabelProps) => {
  switch (props.status) {
    case "UNTEST":
      return (
        <div className="custom_test_status_new_label">
          <p>未テスト</p>
        </div>
      );
    case "TESTED":
      if (props.testPassed) {
        return (
          <div className="custom_test_status_new_label test_passed">
            <Icon className="checked_icon" name="check circle" />
            <p>テスト済</p>
          </div>
        );
      } else {
        return (
          <div className="custom_test_status_new_label test_faild">
            <Icon name="exclamation circle" />
            <p>テスト不合格</p>
          </div>
        );
      }
    default:
      return <></>;
  }
};

export const ContentsStatusLabel = (props: contentsStatusLabelProps) => {
  const circular = (() => {
    if (props.circular) return true;
    return false;
  })();
  switch (props.status) {
    case "NEW":
      return <Label circular={circular} content={"未視聴"} size={props.size} color="grey" />;
    case "VIEWING":
      return <Label circular={circular} content={"視聴中"} size={props.size} color="teal" />;
    case "VIEWED":
      return <Label circular={circular} content={"視聴済"} size={props.size} color="green" />;
    default:
      return <></>;
  }
};

export const ContentsStatusNewLabel = (props: contentsStatusLabelProps) => {
  switch (props.status) {
    case "NEW":
      return (
        <div className="custom_contents_status_new_label">
          <p>未視聴</p>
        </div>
      );
    case "VIEWING":
      return (
        <div className="custom_contents_status_new_label contents_viewing">
          <p>視聴中</p>
        </div>
      );
    case "VIEWED":
      return (
        <div className="custom_contents_status_new_label contents_viewed">
          <p>視聴済</p>
        </div>
      );
    default:
      return <></>;
  }
};

export const PackageStatusNewLabel = (props: packageStatusLabelProps) => {
  switch (props.status) {
    case "NOT_STUDY":
      return (
        <div className="custom_package_status_new_label">
          <div>
            <p>未受講</p>
          </div>
        </div>
      );
    case "STUDYING":
      return (
        <div className="custom_package_status_new_label package_studying">
          <div>
            <p>受講中</p>
          </div>
        </div>
      );
    case "STUDIED":
      return (
        <div className="custom_package_status_new_label package_studied">
          <div>
            <Icon className="checked_icon" name="check circle" />
            <p>修了済</p>
          </div>
        </div>
      );
    default:
      return <></>;
  }
};

export const QuestionStatusLabel = (props: questionBoxStatusLabelProps) => {
  const circular = (() => {
    if (props.circular) return true;
    return false;
  })();
  switch (props.status) {
    case "NEW":
      return <Label circular={circular} content="新規" size={props.size} color="grey" />;
    case "NOT_REPLY":
      return <Label circular={circular} content="未返答" size={props.size} color="teal" />;
    case "REPLIED":
      return <Label circular={circular} content="返答済" size={props.size} color="green" />;
    default:
      return <></>;
  }
};

export const QuestionStatusAccountLabel = (props: questionBoxStatusLabelProps) => {
  const circular = (() => {
    if (props.circular) return true;
    return false;
  })();
  switch (props.status) {
    case "NEW":
    case "NOT_REPLY":
      return <Label circular={circular} content="返答待ち" size={props.size} color="grey" />;
    case "REPLIED":
      return <Label circular={circular} content="返答あり" size={props.size} color="green" />;
    default:
      return <></>;
  }
};

export const ValidStatusLabel = (props: ValidStatusLabelProps) => {
  const circular = (() => {
    if (props.circular) return true;
    return false;
  })();

  switch (props.status) {
    case "VALID":
      return <Label circular={circular} content="有効" size={props.size} color="blue" />;
    case "IMPORTING":
      return <Label circular={circular} content="登録処理中" size={props.size} color="teal" />;
    case "STOPPED":
      return <Label circular={circular} content="無効" size={props.size} color="red" />;
    case "INVALID":
      return <Label circular={circular} content="無効" size={props.size} color="red" />;
    case "DELETED":
      return <Label circular={circular} content="削除済" size={props.size} color="red" />;
    case "":
      return <Label circular={circular} content="下書き" size={props.size} color="grey" />;
    default:
      return <></>;
  }
};

export const UserStatusLabel = (props: UserStatusLabelProps) => {
  const circular = (() => {
    if (props.circular) return true;
    return false;
  })();

  if (props.status !== "VALID") {
    return <ValidStatusLabel status={props.status} circular={circular} size={props.size} />;
  }

  switch (props.inviteStatus) {
    case "NOT_USED":
    case "SIGNEDUP":
      return <ValidStatusLabel status={props.status} circular={circular} size={props.size} />;
    case "INVITING":
      return <Label circular={circular} content="招待中" size={props.size} color="yellow" />;
    case "CANCELED":
      return <Label circular={circular} content="招待無効" size={props.size} color="grey" />;
  }
  return <></>;
};

export const ReleaseFlgLabel = (props: { flg: boolean }) => {
  switch (props.flg) {
    case true:
      return <Label content="公開" size="mini" color="green" />;
    case false:
      return <Label content="非公開" size="mini" color="grey" />;
    default:
      return <></>;
  }
};

export const ContentsPassLabel = (props: { status: "UNTESTED" | "PASS" | "FAILURE" }) => {
  switch (props.status) {
    case "UNTESTED":
      return <Label circular content="未テスト" size="mini" color="grey" />;
    case "PASS":
      return <Label circular content="合格" size="mini" color="blue" />;
    case "FAILURE":
      return <Label circular content="不合格" size="mini" color="pink" />;
    default:
      return <></>;
  }
};

export const MediaUploadStatusLabel = (props: mediaUploadStatusLabelProps) => {
  const circular = (() => {
    if (props.circular) return true;
    return false;
  })();
  switch (props.status) {
    case "WAITING":
      return <Label circular={circular} content="処理待ち" size={props.size} color="grey" />;
    case "PROCESSING":
      return <Label circular={circular} content="処理中" size={props.size} color="teal" />;
    case "SUCCESS":
      return <Label circular={circular} content="処理済" size={props.size} color="green" />;
    case "FAILED":
      return <Label circular={circular} content="処理エラー" size={props.size} color="red" />;
    default:
      return <></>;
  }
};

export const BulkUserHistoryStatusLabel = (props: bulkUserHistoryStatusLabelProps) => {
  const circular = (() => {
    if (props.circular) return true;
    return false;
  })();
  switch (props.status) {
    case "WAITING_AUTH0_JOB":
      return <Label circular={circular} content="処理待ち" size={props.size} color="teal" />;
    case "AUTH0_PROCESSING":
      return <Label circular={circular} content="処理中" size={props.size} color="teal" />;
    case "SUCCESS":
      return <Label circular={circular} content="処理済" size={props.size} color="green" />;
    case "FAILED":
      return <Label circular={circular} content="システムエラー" size={props.size} color="red" />;
    default:
      return <></>;
  }
};

export const RequireIcon = () => {
  return <label className="required_icon">*</label>;
};

export const LevelLabel = (props: levelabelProps) => {
  const circular = (() => {
    if (props.circular) return true;
    return false;
  })();

  const getLabelColor = (level: number) => {
    switch (level) {
      case 1:
        return levelColorCodes.level1Color;
      case 2:
        return levelColorCodes.level2Color;
      case 3:
        return levelColorCodes.level3Color;
      case 4:
        return levelColorCodes.level4Color;
      case 5:
        return levelColorCodes.level5Color;
      default:
        return levelColorCodes.level1Color;
    }
  };

  if (typeof props.level === "number") {
    return (
      <Label
        circular={circular}
        content={`Lv.${String(props.level)}`}
        size={props.size}
        style={{ backgroundColor: getLabelColor(props.level), color: "white" }}
      />
    );
  } else {
    return (
      <Label
        circular={circular}
        content={props.level}
        size={props.size}
        style={{ backgroundColor: getLabelColor(getOnlyNumber(props.level)), color: "white" }}
      />
    );
  }
};

export const CategoryTestPassLabel = (props: categoryTestPassLabelProps) => {
  const circular = (() => {
    if (props.circular) return true;
    return false;
  })();
  switch (props.pass) {
    case true:
      return <Label circular={circular} content="合格" size={props.size} color="blue" />;
    case false:
      return <Label circular={circular} content="不合格" size={props.size} color="grey" />;
    default:
      return <></>;
  }
};

export const CustomSubMenu = <T,>(props: customSubMenuProps<T>) => {
  const close = () => {
    props.onCloseCallback({
      ...props.options,
      visible: false,
    });
  };
  const menuActions = props.menuActions(props.options.item);
  if (props.options.visible && menuActions.length !== 0) {
    return (
      <div
        className="subMenu"
        style={{ position: "absolute", height: "100%", width: "100%", top: 0, left: 0 }}
        onClick={() => close()}
      >
        <Menu
          style={{
            top: props.options.top - props.options.staticTop,
            left: props.options.left - props.options.staticLeft,
            position: "absolute",
            width: "auto",
          }}
          vertical
          floated
        >
          {menuActions.map((menu, index) => {
            return (
              <Menu.Item onClick={() => props.options.item && menu.onClick()} key={`menu_${index}`}>
                {menu.label}
                {menu.icon && <Icon name={menu.icon} />}
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    );
  } else {
    return <React.Fragment />;
  }
};

export const CustomTable = (props: customTableProps) => {
  const navigate = useNavigate();
  const onsSort = props.onSort;

  const { sortColumn, desc } = props.property;

  const TableHeaders = (props: customTableHeaderProps) => {
    const headerData: JSX.Element[] = [];

    props.items.forEach((item: customTableHeaderType, index: number) => {
      headerData.push(
        <Table.HeaderCell
          key={`hd_cell_${index}`}
          textAlign="center"
          className="table_header"
          sorted={item.sortable && item.key == sortColumn ? (desc ? "descending" : "ascending") : undefined}
          onClick={
            item.sortable &&
            (() => {
              const nextSortInfo = { column: item.key, desc: desc ? false : true };
              onsSort && onsSort(nextSortInfo.column, nextSortInfo.desc);
            })
          }
          width={item.width}
        >
          {item.name}
          {props.property.sortTableColumn === item.key && item.sortable && props.property.desc !== undefined && (
            <Icon name={props.property.desc ? "angle down" : "angle up"} />
          )}
        </Table.HeaderCell>
      );
    });
    return (
      <Table.Header>
        <Table.Row>{headerData}</Table.Row>
      </Table.Header>
    );
  };

  const rowAction = (link: string | undefined, click: () => void) => {
    if (link !== undefined) {
      if (link !== "unlink") {
        navigate(link);
        return;
      }
      return;
    }
    if (click !== undefined) {
      click();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cellContextAction = (e: any, idx: number, key: string) => {
    if (!props.subMenuOpen) return;
    props.subMenuOpen(e.nativeEvent.clientY, e.nativeEvent.clientX, idx, key) && e.preventDefault();
  };

  const TableRows = (props: customTableRowProps) => {
    if (props.property.loading) {
      return (
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan={props.property.cols}>
              <Loader active inline="centered">
                読み込んでいます......
              </Loader>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      );
    } else {
      const rowData: JSX.Element[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      props.items.forEach((item: any, rowIndex: number) => {
        const cellData: JSX.Element[] = [];
        props.header.forEach((header: customTableHeaderType, colIndex: number) => {
          const align = (() => {
            if (header.align === "center") {
              return "align_center";
            }
            return "";
          })();
          if (header.click) {
            cellData.push(
              <Table.Cell
                className={
                  item.cellClass ? `cursor_hand ${align} ` + item.cellClass(header.key) : `cursor_hand ${align}`
                }
                key={`bd_cell_${rowIndex}_${colIndex}`}
                onClick={() => rowAction(item.link, item.click)}
                width={header.width}
                active={item.active && item.active(header.key)}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onContextMenu={(e: any) => {
                  cellContextAction(e, rowIndex, header.key);
                }}
              >
                {textOverflow(item[header.key], header.limit)}
              </Table.Cell>
            );
          } else {
            cellData.push(
              <Table.Cell
                className={
                  item.cellClass ? `cursor_hand ${align} ` + item.cellClass(header.key) : `cursor_hand ${align}`
                }
                key={`bd_cell_${rowIndex}_${colIndex}`}
                width={header.width}
                active={item.active && item.active(header.key)}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onContextMenu={(e: any) => {
                  cellContextAction(e, rowIndex, header.key);
                }}
              >
                {textOverflow(item[header.key], header.limit)}
              </Table.Cell>
            );
          }
        });
        rowData.push(<Table.Row key={rowIndex}>{cellData}</Table.Row>);
      });
      return <Table.Body>{rowData}</Table.Body>;
    }
  };

  const pageLoad = (activePage: number | string | undefined) => {
    if (props.load !== undefined && props.setProperty !== undefined) {
      props.setProperty({ ...props.property, page: Number(activePage) });
      props.load(Number(activePage));
    }
  };

  const TablePagenation = (props: tablePagenationProps) => {
    if (props.property.pages > 0) {
      return (
        <div className="custom_table_pagination">
          <Pagination
            defaultActivePage={props.property.page}
            totalPages={props.property.pages}
            onPageChange={(e, { activePage }) => pageLoad(activePage)}
          />
        </div>
      );
    } else {
      return <React.Fragment />;
    }
  };

  const TableLoading = () => {
    return (
      <Table.Body>
        <Table.Row>
          <Table.Cell colSpan={props.property.cols}>
            <Loader active inline="centered">
              Loading...
            </Loader>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    );
  };

  return (
    <React.Fragment>
      <div className="custom_table">
        {props.reload && (
          <div className="custom_table-reload">
            <Button icon="redo" onClick={() => props.load !== undefined && props.load(Number(props.property.page))} />
          </div>
        )}
        {props.body.length > 0 ? (
          <Table
            striped={!props.cancelStriped}
            unstackable
            selectable
            compact
            celled={props.celled}
            fixed={props.fixed}
            sortable={!!props.onSort}
            size={props.size}
          >
            <TableHeaders items={props.header} property={props.property} />
            {props.loading ? (
              <TableLoading />
            ) : (
              <TableRows header={props.header} property={props.property} items={props.body} />
            )}
          </Table>
        ) : (
          <>{props.loading ? <></> : <p className="information">データがありません。</p>}</>
        )}
      </div>
      {props.paging && <TablePagenation property={props.property} load={props.load} />}
    </React.Fragment>
  );
};

export const CustomLoader = (props: customLoaderProps) => {
  const getLoadingText = () => {
    if (props.isLoading) {
      return {
        loading: true,
        text: "読み込んでいます...",
      };
    }
    if (props.isSaving) {
      return {
        loading: true,
        text: "登録しています...",
      };
    }
    if (props.isCopying) {
      return {
        loading: true,
        text: "コピーしています...",
      };
    }
    if (props.isUploading) {
      return {
        loading: true,
        text: "アップロードしています...",
      };
    }
    if (props.isDownloading) {
      return {
        loading: true,
        text: "ダウンロードしています...",
      };
    }
    if (props.isUpdating) {
      return {
        loading: true,
        text: "更新しています...",
      };
    }
    if (props.isDeleting) {
      return {
        loading: true,
        text: "削除しています...",
      };
    }
    return {
      loading: false,
      text: "",
    };
  };
  const { loading, text } = getLoadingText();
  if (loading) {
    return (
      <Dimmer active={true} page={props.inline === undefined || !props.inline}>
        <Loader>{text}</Loader>
      </Dimmer>
    );
  }
  return <></>;
};

export const CustomModal = (props: customModalProps) => {
  const mode = (() => {
    if (props.mode === "error") {
      return "modalError";
    }
    return "";
  })();
  return (
    <Modal
      open={props.open}
      size={props.size ?? "mini"}
      className="custom_modal"
      closeIcon
      onClose={() => {
        props.close();
      }}
    >
      <Modal.Content className={mode}>{props.bodyText}</Modal.Content>
      <Modal.Actions>
        <Button color="grey" onClick={() => props.close()}>
          閉じる
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const CustomConfirmModal = (props: customConfirmModalProps) => {
  return (
    <Modal
      open={props.open}
      size={props.size ?? "mini"}
      closeIcon
      className={props.className ? `custom_modal ${props.className}` : "custom_modal"}
      loading={props.loading}
      onClose={() => {
        props.close(false);
      }}
    >
      <Modal.Header>{props.headerText}</Modal.Header>
      <Modal.Content>
        {props.loading && (
          <Dimmer active inverted>
            <Loader active inline="centered" />
          </Dimmer>
        )}
        {props.bodyText}
      </Modal.Content>
      <Modal.Actions>{props.actionsChildren}</Modal.Actions>
    </Modal>
  );
};

export const CustomInputModal = (props: customInputModalProps) => {
  return (
    <Modal
      open={props.open}
      closeIcon={props.closeIcon !== undefined ? props.closeIcon : true}
      closeOnDimmerClick={props.closeOnDimmerClick !== undefined ? props.closeOnDimmerClick : true}
      className={`custom_input_modal ${props.className ? props.className : ""}`}
      onClose={() => {
        props.close();
      }}
      size={props.size ? props.size : "tiny"}
      style={props.style && props.style}
    >
      <Modal.Header>
        {props.icon ? <Icon name={props.icon} /> : <></>}
        {props.header}
      </Modal.Header>
      <Modal.Content scrolling={props.scrolling ? true : false}>{props.contentChildren}</Modal.Content>
      {props.footerChildren && <Modal.Actions>{props.footerChildren}</Modal.Actions>}
    </Modal>
  );
};

export const CustomFeedbackModal = (props: customInputModalProps) => {
  return (
    <Modal
      open={props.open}
      className="custom_input_modal"
      closeOnDimmerClick={false}
      onClose={() => {
        props.close();
      }}
      size="mini"
    >
      <Modal.Content>{props.contentChildren}</Modal.Content>
    </Modal>
  );
};

export const ImpressionStatus = (props: impressionStatusProps) => {
  if (props.impressionFlg === 1) {
    if (props.reportFinished) {
      return <p>感想レポート：提出済</p>;
    }
    return <p>感想レポート：未提出</p>;
  }
  return <></>;
};

export const ThemeProgress = (props: themeProgressProps) => {
  const rate = (() => {
    if (props.contentsCount === 0 || props.contentsCountFinished === 0) {
      return 0;
    }
    return Math.floor((props.contentsCountFinished / props.contentsCount) * 100);
  })();
  return (
    <p>
      進捗：{props.contentsCountFinished} / {props.contentsCount} ({rate}%)
    </p>
  );
};

export const NoQuestion = () => {
  return (
    <Segment className="question_message">
      <p>まだ質問はありません。</p>
      <p>受講して不明な点があれば是非質問してみてください！</p>
    </Segment>
  );
};

export const CustomProgress = (props: customProgressProps) => {
  if (props.numerator === 0 && props.denominator === 0) return <></>;
  return (
    <div className="custom_progress">
      <Progress className="progress_bar" percent={props.rate} progress color={props.rate == 0 ? "grey" : "blue"} />
      <p className="progress_count">{`（${props.numerator} / ${props.denominator} ${
        props.unit === undefined ? "件" : props.unit
      }）`}</p>
    </div>
  );
};

export const CustomInputText = (props: customInputTextProps) => {
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);
  const [copyMessage, setCopyMessage] = useState<boolean>(false);
  const isImeOn = useRef<boolean>(false);
  const copyToCripboard = (value: string) => {
    navigator.clipboard.writeText(value);
    setCopyMessage(true);
    setTimeout(() => {
      setCopyMessage(false);
    }, 3000);
  };
  const changeValue = (data: InputProps) => {
    props.onEventCallback(props.code, data.target.value, isImeOn.current);
  };
  const switchVisiblePassword = () => {
    setVisiblePassword(!visiblePassword);
  };
  const getType = (type: string | undefined) => {
    if (type === "password") {
      return visiblePassword ? "text" : "password";
    }
    if (type !== undefined) {
      return type;
    }
    return "text";
  };
  return (
    <div
      className={`custom_input ${props.copyButton ? "copy_cripboard" : ""} ${props.className || ""} ${
        props.nolabel ? "custom_input_nolabel" : ""
      }`}
    >
      {!props.nolabel && (
        <div className="label">
          <label>{props.label}</label>
          {props.required && <RequireIcon />}
        </div>
      )}
      <div className="input_body">
        {/* ブラウザ設定によるパスワードや住所・クレジットカード情報などの自動入力機能をoffにするため、見えないラベルを埋め込む */}
        {props.disabledAutoComplete && <label className="auto-complete-off">auto-complete-off</label>}
        <Input
          className={`input_element ${props.widthSize ? `size_${props.widthSize}` : ""} ${
            props.disabledAutoComplete ? "autocomplete-off" : ""
          }`}
          icon={props.searchIcon && <Icon className="search_icon" name="search" link onClick={props.search} />}
          disabled={props.disabled}
          value={props.value}
          type={getType(props.type)}
          placeholder={props.placeholder}
          autoComplete={props.disabledAutoComplete ? "off" : props.autoCompleteType}
          onChange={(e) => changeValue(e)}
          onCompositionStart={() => {
            isImeOn.current = true;
          }}
          onCompositionEnd={() => {
            isImeOn.current = false;
            props.onEventCallback(props.code, props.value, false);
          }}
          error={props.error}
          {...(props.onKeyPress && { onKeyPress: props.onKeyPress })}
        />
        {props.type === "password" && props.disabled === false && (
          <div className="input_password_visible">
            <Icon name={visiblePassword ? "eye slash" : "eye"} onClick={switchVisiblePassword} />
          </div>
        )}
      </div>
      {props.copyButton && typeof props.value === "string" && (
        <div className="copy_button_area">
          <div className="copy_button" onClick={() => copyToCripboard(props.value as string)}>
            <p>コピー</p>
          </div>
        </div>
      )}
      {props.copyButton && copyMessage && <p className="copy_message">コピーしました</p>}
    </div>
  );
};

export const CustomInputNumber = (props: customInputNumberProps) => {
  const changeValue = (data: InputProps) => {
    const value = Number(data.target.value.replace(/[^0-9]/g, ""));
    if (value >= props.min && value <= props.max) {
      props.onEventCallback(props.code, value);
      return;
    }
  };
  return (
    <React.Fragment>
      <div className="custom_input">
        <div className="label">
          <label>{props.label}</label>
          {props.required && <RequireIcon />}
        </div>
        <div className="input_body">
          <Input
            className={`input_element ${props.widthSize && `size_${props.widthSize}`}`}
            disabled={props.disabled}
            value={String(props.value)}
            type="number"
            placeholder={props.placeholder}
            onChange={(e) => changeValue(e)}
            error={props.error}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export const CustomInputCheckBox = (props: customSingleCheckBoxProps) => {
  const changeValue = (data: CheckboxProps) => {
    props.onEventCallback(props.code, undefinedToBoolean(data.checked, false));
  };
  return (
    <div className="custom_input">
      <div className="label">
        <label>{props.label}</label>
        {props.required && <RequireIcon />}
      </div>
      <div className="input_body">
        <div className="checkbox_wrapper">
          <Checkbox
            toggle={props.toggle}
            disabled={props.disabled}
            checked={props.value}
            onChange={(e, data) => changeValue(data)}
          />
        </div>
      </div>
    </div>
  );
};

export const CustomInputModeCheckBox = (props: customSingleCheckBoxProps) => {
  const changeValue = (data: CheckboxProps) => {
    props.onEventCallback(props.code, undefinedToBoolean(data.checked, false));
  };
  return (
    <div className="custom_input_mode_checkbox">
      <div className="input_body">
        <div className="checkbox_wrapper">
          <Checkbox
            toggle={props.toggle}
            disabled={props.disabled}
            checked={props.value}
            onChange={(e, data) => changeValue(data)}
          />
        </div>
      </div>
      <div
        className={`label ${props.disabled ? "label_disabled" : ""}`}
        onClick={() => (props.disabled ? {} : changeValue({ checked: !props.value }))}
      >
        <label>{props.label}</label>
        {props.required && <RequireIcon />}
      </div>
    </div>
  );
};

export const CustomRadio = (props: customRadioProps) => {
  const selected = props.selected;
  const verticalClass = props.vertical ? "vertical" : "";
  const changeValue = (data: string) => {
    props.onEventCallback(props.code, data);
  };
  const RadioItem = (props: customRadioItemProps) => {
    if (selected === props.value) {
      return (
        <Radio
          label={props.label}
          name={props.name}
          onChange={() => changeValue(props.value)}
          defaultChecked
          disabled={props.disabled}
        />
      );
    } else {
      return (
        <Radio
          label={props.label}
          name={props.name}
          onChange={() => changeValue(props.value)}
          disabled={props.disabled}
        />
      );
    }
  };
  return (
    <div className={`custom_input ${verticalClass}`}>
      <div className="label">
        <label>{props.label}</label>
      </div>
      <div className="input_body">
        <div className={`radio_wrapper ${verticalClass}`}>
          {props.choices.map((choice: customRadioChoiceType, index) => (
            <RadioItem
              key={`choice_${props.code}_${index}`}
              value={choice.value}
              label={choice.label}
              name={props.code}
              disabled={props.disabled}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export const CustomRadioV2 = (props: customRadioProps) => {
  const selected = props.selected;
  const verticalClass = props.vertical ? "vertical" : "";
  const changeValue = (data: string) => {
    props.onEventCallback(props.code, data);
  };
  const RadioItem = (props: customRadioItemProps) => {
    if (selected === props.value) {
      return (
        <Radio
          label={props.label}
          name={props.name}
          onChange={() => changeValue(props.value)}
          defaultChecked
          disabled={props.disabled}
        />
      );
    } else {
      return (
        <Radio
          label={props.label}
          name={props.name}
          onChange={() => changeValue(props.value)}
          disabled={props.disabled}
        />
      );
    }
  };
  return (
    <div className={`custom_input_radio_v2 ${verticalClass}`}>
      <List className="answer">
        {props.choices.map((choice: customRadioChoiceType, index) => (
          <List.Item
            key={`choice_${props.code}_${index}`}
            className={
              choice.value === selected
                ? `list_checked ${(props.borderDashed === undefined || props.borderDashed) && "border_dash"}`
                : ""
            }
          >
            <RadioItem
              key={`choice_${props.code}_${index}`}
              value={choice.value}
              label={choice.label}
              name={props.code}
              disabled={props.disabled}
            />
          </List.Item>
        ))}
      </List>
    </div>
  );
};

export const CustomInputTextArea = (props: customInputTextProps) => {
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);
  const changeValue = (data: TextAreaProps) => {
    props.onEventCallback(props.code, data.target.value);
  };
  const switchVisiblePassword = () => {
    setVisiblePassword(!visiblePassword);
  };
  const getType = (type: string | undefined) => {
    if (type === "password") {
      return visiblePassword ? "text" : "password";
    }
    if (type !== undefined) {
      return type;
    }
    return "text";
  };
  return (
    <div className="custom_input">
      <div className="label">
        <label>{props.label}</label>
        {props.required && <RequireIcon />}
      </div>
      <div className="input_body">
        <Form>
          <TextArea
            className={`input_element ${props.disabled && "input_textarea_disabled"}`}
            disabled={props.disabled}
            value={props.value}
            type={getType(props.type)}
            placeholder={props.placeholder}
            onChange={(e) => changeValue(e)}
          />
        </Form>
        {props.type === "password" && (
          <div className="input_password_visible">
            <Icon name={visiblePassword ? "eye slash" : "eye"} onClick={switchVisiblePassword} />
          </div>
        )}
      </div>
    </div>
  );
};

export const CustomReadOnlyText = (props: customReadOnlyTextProps) => {
  const [copyInformation, setCopyMessage] = useState<boolean>(false);
  const copyToCripboard = (value: string) => {
    navigator.clipboard.writeText(value);
    setCopyMessage(true);
    setTimeout(() => {
      setCopyMessage(false);
    }, 3000);
  };
  return (
    <div
      className={`custom_readonly ${props.copyButton ? "copy_cripboard" : ""} ${
        props.className ? props.className : ""
      }`}
    >
      <div className="label">
        <label>{props.label}</label>
        {props.required && <RequireIcon />}
        {props.hint && <HintIcon text={props.hint} />}
      </div>
      <div className="input_body">
        <div>{props.value}</div>
        {props.copyButton && typeof props.value === "string" && (
          <div className="copy_button_area">
            <div className="copy_button" onClick={() => copyToCripboard(props.value as string)}>
              <p>コピー</p>
            </div>
            {props.copyButton && copyInformation && <p className="copy_information">コピーしました</p>}
          </div>
        )}
      </div>
    </div>
  );
};

export const CustomDropdown = (props: customDropdownProps) => {
  const changeValue = (data: DropdownProps) => {
    props.onEventCallback(props.code, data.value);
  };
  return (
    <div className="custom_input">
      <div className="label">
        <label>{props.label}</label>
        {props.required && <RequireIcon />}
        {props.hintText && <HintTextIcon text={props.hintText} />}
      </div>
      <div className="input_body dropdown">
        <Dropdown
          className={`dropdown_element ${props.widthSize && `size_${props.widthSize}`}`}
          selection
          clearable={props.clearable}
          options={props.options}
          disabled={props.disabled}
          value={props.value}
          loading={props.loading}
          search={props.search}
          onChange={(e, data) => changeValue(data)}
        />
      </div>
    </div>
  );
};

export const CustomMultipleDropdown = (props: customMultipleDropdownProps) => {
  const changeValue = (data: DropdownProps) => {
    props.onEventCallback(props.code, data.value);
  };
  return (
    <div className="custom_input">
      <div className="label">
        <label>{props.label}</label>
        {props.required && <RequireIcon />}
        {props.hintText && <HintTextIcon text={props.hintText} />}
      </div>
      <div className="input_body multiple_dropdown">
        <Dropdown
          className={`multiple_dropdown_element ${props.widthSize && `size_${props.widthSize}`}`}
          selection
          options={props.options}
          disabled={props.disabled}
          value={props.value}
          loading={props.loading}
          search={props.search}
          multiple
          onChange={(e, data) => changeValue(data)}
        />
      </div>
    </div>
  );
};

export const CustomLimitDropdown = (props: customLimitDropdownProps) => {
  return (
    <div className="limit_dropdown">
      <div className="label">
        <p>表示件数</p>
      </div>
      <div>
        <Dropdown
          className="limit_dropdown_element"
          selection
          options={limitOptions}
          value={props.value}
          onChange={(e, data) => props.onChange(Number(data.value))}
        />
      </div>
    </div>
  );
};

export const CustomMultiDropdown = <T,>(props: customMultiDropdownProps<T>) => {
  const onChange = (mode: "select" | "remove", data: string) => {
    if (data !== undefined) {
      if (mode === "select") {
        props.onSelectEventCallback(props.code, data);
      } else if (mode === "remove") {
        props.onRemoveEventCallback(props.code, data);
      }
    }
  };
  return (
    <div className={`custom_input ${props.className}`}>
      <div className="label">
        <label>{props.label}</label>
        {props.required && <RequireIcon />}
      </div>
      <div className="input_body mapping">
        <Dropdown
          placeholder={props.placeholder}
          className="mapping_element"
          fluid
          search
          selection
          disabled={props.disabled}
          options={props.options}
          selectOnBlur={false}
          onChange={(e, data) => onChange("select", data.value as string)}
        />
        <div className="mapped_items">
          {props.mappingItems.map((item) => {
            return (
              <div key={`mapping_item_${props.removeTargetValue(item)}`} className="mapping_item">
                <div
                  className={`mapping_information with_click_event`}
                  onClick={() => props.onClickMappingItem && props.onClickMappingItem(item)}
                >
                  {props.mappingItemView(item)}
                </div>
                <div className="item_remove">
                  <Icon
                    name="trash"
                    color="grey"
                    onClick={() => onChange("remove", props.removeTargetValue(item) as string)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const CustomDatePicker = (props: customInputDatePickerProps) => {
  const initialDate = new Date();
  initialDate.setHours(0);
  initialDate.setMinutes(0);
  initialDate.setSeconds(0, 0);
  const selected = (() => {
    if (props.value) {
      return new Date(props.value);
    } else {
      return initialDate;
    }
  })();

  const changeValue = (data: Date | null) => {
    if (data !== null) {
      props.onEventCallback(props.code, data);
    } else {
      props.onEventCallback(props.code, initialDate);
    }
  };

  return (
    <div className="custom_input">
      <div className="label">
        <label>{props.label}</label>
        {props.required && <RequireIcon />}
      </div>
      <div className={`input_body flex_layout ${props.unsetMin ? "unset_min" : ""}`}>
        <DatePicker
          locale="ja"
          selected={props.disabled ? null : selected}
          showTimeSelect={false}
          dateFormat={"yyyy/MM/dd"}
          onChange={(e) => changeValue(e)}
          disabled={props.disabled}
        />
        {props.children && props.children}
      </div>
    </div>
  );
};

export const StatisticCount = (props: statisticCountProps) => {
  const statisticCountTypeClass = (props.statisticCountType || "PRIMARY").toLocaleLowerCase();
  const actionClass = props.onClickNumerator ? "has_action" : "";
  return (
    <div className={`statistic count ${statisticCountTypeClass} ${actionClass}`} onClick={props.onClickNumerator}>
      <div className="value">
        <p className={`count_value ${props.color}`}>
          <span>{props.numerator}</span>
          <span className={props.denominator_color ? `denominator ${props.denominator_color}` : `denominator black`}>
            / {props.denominator}
          </span>
        </p>
      </div>
      <div className={`label ${props.color}`}>
        <p>{props.label}</p>
      </div>
    </div>
  );
};

export const StatisticLevel = (props: statisticLevelProps) => {
  return (
    <div className={`statistic level ${props.size || ""}`} style={{ color: `${props.color}` }}>
      {props.labelPosition == "top" && (
        <div className="label">
          <p>{props.label}</p>
        </div>
      )}
      <div className="value">
        <p>{props.value}</p>
      </div>
      {props.labelPosition != "top" && (
        <div className="label bottom_label">
          <p>{props.label}</p>
        </div>
      )}
    </div>
  );
};

export const StatisticCustomUnit = (props: statisticCustomUnittProps) => {
  return (
    <div className={`statistic_percent level ${props.size || ""}`}>
      <div className="value">
        <p className={props.color}>{props.value}</p>
      </div>
      <div className="unit">
        <p>{props.unit}</p>
      </div>
    </div>
  );
};

export const CardRibbonTitle = (props: { title: string }) => {
  return (
    <div className="ribbon_header">
      <Label color="blue" ribbon content={props.title} className="title" size="big" />
    </div>
  );
};

export const CardProgress = (props: progressCardProps) => {
  const progressPercent = Math.round(
    (props.studiedContentCount / (props.notStudyContentCount + props.studiedContentCount + props.studingContentCount)) *
      100
  );
  const studingProgress = Math.round(
    ((props.studiedContentCount + props.studingContentCount) /
      (props.notStudyContentCount + props.studiedContentCount + props.studingContentCount)) *
      100
  );

  const LabelArea = () => {
    return (
      <>
        <div className="status_count">
          <div className="statistic_count_wrapper">
            <StatisticCount
              color="blue"
              label="合格"
              numerator={props.studiedContentCount}
              denominator={props.testCount}
              onClickNumerator={props.onClickStudiedContensts}
              statisticCountType={props.deviceType == "SP" ? "SP-PRIMARY" : undefined}
            />
          </div>
          <div className="arrow">
            <div className="left_arrow"></div>
          </div>
          <div className="statistic_count_wrapper">
            <StatisticCount
              color="pink"
              label="不合格"
              numerator={props.studingContentCount}
              denominator={props.testCount}
              onClickNumerator={props.onClickStudingContensts}
              statisticCountType={props.deviceType == "SP" ? "SP-PRIMARY" : undefined}
            />
          </div>
          <div className="arrow">
            <div className="left_arrow"></div>
          </div>
          <div className="statistic_count_wrapper">
            <StatisticCount
              color="grey"
              label="未テスト"
              numerator={props.notStudyContentCount}
              denominator={props.testCount}
              onClickNumerator={props.onClickNotStudyContensts}
              statisticCountType={props.deviceType == "SP" ? "SP-PRIMARY" : undefined}
            />
          </div>
        </div>
      </>
    );
  };
  const Horizontal = () => {
    return (
      <div className="horizontal_custom_progress">
        <div className="left">
          <CustomProgressWithWalkIcon progressPercent={progressPercent} secondaryProgressPercent={studingProgress} />
        </div>
        <div className="right">
          <LabelArea />
        </div>
      </div>
    );
  };
  const Vertical = () => {
    return (
      <div
        className={`vertical-custom_progress ${props.deviceType == "SP" ? "sp_custom_progress" : "pc_custom_progress"}`}
      >
        <div className="top">
          <CustomProgressWithWalkIcon progressPercent={progressPercent} secondaryProgressPercent={studingProgress} />
        </div>
        <div className="bottom">
          <LabelArea />
        </div>
      </div>
    );
  };

  return (
    <div className="card_progress">
      {props.deviceType == "PC" ? (
        props.layout == "horizontal" ? (
          <Horizontal />
        ) : (
          <Vertical />
        )
      ) : (
        // spは常にverticalデザイン
        <Vertical />
      )}
    </div>
  );
};

export const CustomProgressWithWalkIcon = (props: customProgressWithWalkIconProps) => {
  const progressIconCSS = props.progressPercent + 5 + "%";
  const secondaryProgressCSS = props.secondaryProgressPercent + "%";
  return (
    <div className="custom_progress_with_walk_icon">
      <div className="icon_on_progress">
        <div
          className={props.progressPercent === 100 ? "icon_walk finished" : "icon_walk"}
          style={props.progressPercent > 95 ? { width: "97%" } : { width: progressIconCSS }}
        >
          <MdDirectionsWalk size={30} color="#767676" />
        </div>
        <div className={props.progressPercent === 100 ? "icon_flag finished" : "icon_flag"} style={{ width: "100%" }}>
          {props.progressPercent === 100 ? (
            <>
              <MdFlag className="finished_flag_icon" size={30} color="#767676" />
              <MdEmojiPeople className="finished_people_icon" size={30} color="#767676" />
              <MdFlag className="finished_first_icon" size={30} color="#767676" />
            </>
          ) : (
            <MdFlag size={30} color="#767676" />
          )}
        </div>
      </div>
      <div className="dual_progress">
        <Progress
          percent={props.progressPercent}
          color={props.progressPercent == 0 || isNaN(props.progressPercent) ? "grey" : "blue"}
          progress="percent"
          className={props.progressPercent <= 12 ? "single_digit" : "multi_digit"}
        />
        <div className="secondary_progress" style={{ width: secondaryProgressCSS }}></div>
      </div>
    </div>
  );
};

export const CustomProgressIconAndMedal = (props: customProgressIconAndMedalProps) => {
  return (
    <div className="custom_progress_icon_and_medal">
      <style>
        {`@keyframes progressSlide {
        0% {
          width: 0%;
        }
        100% {
          width: ${props.progressPercent}%;
        }
      }`}
      </style>
      <div className="icon_on_progress">
        <div className="progress_balloon">
          <div className="progress_balloon_content">
            <div className="balloon">
              <div className="balloon_content">
                <div className="percent">
                  <p>
                    {props.progressPercent}
                    <span>%</span>
                  </p>
                </div>
                <div className="arrow_icons">
                  <Image src={iconUp} />
                </div>
              </div>
              <div className="balloon_shape_wrap">
                <div className="balloon_shape" />
              </div>
            </div>
            <div className={`smile_icon ${props.package ? "contents_view_label" : ""}`}>
              <Image src={progressMedalCurrent} />
              {props.package && <p className="contents_view_label">動画視聴</p>}
            </div>
          </div>
        </div>
        <div className="medal">{props.useMedalIcon && <Image src={progressMedal} />}</div>
      </div>
      <div className="gradation_progress">
        <div className="primary_progress"></div>
        <div className="progress_background"></div>
      </div>
    </div>
  );
};

export const ThemeItem = (props: themeItemProps) => {
  return (
    <>
      <div className="theme_item">
        <div className="left">
          <div className="thumbnail_wrapper">
            <Image src={props.image} className="thumbnail" />
          </div>
        </div>
        <div className="right">
          <div className="info">
            <div className="item_header">
              <div>
                <p className="title">{props.name}</p>
              </div>
              <div className="status">
                <div className="progress">
                  <ImpressionStatus impressionFlg={props.impressionFlg} reportFinished={props.reportFinished} />
                  <ThemeProgress
                    contentsCount={props.contentsCount}
                    contentsCountFinished={props.contentsCountFinished}
                  />
                  <p>合計時間：{getDurationString(props.totalPlaytime)}</p>
                </div>
                <div>
                  <ThemeStatusLabel status={props.studyStatus} size="medium" />
                </div>
              </div>
            </div>
            <div>
              <p className="description">{props.description}</p>
            </div>
          </div>
        </div>
      </div>
      {undefinedToNumber(props.length, 0) - 1 !== props.index && <Divider />}
    </>
  );
};

export const ContentItem = (props: customContentsItemProps) => {
  const contentsStatus = props.contentsStatus;
  const disabledTestStatus = isDisabledTest(props.contentsStatus, props.viewCount, props.requiredTest);
  const testStatus = !props.requiredTest ? "UNTEST" : props.existTest ? "TESTED" : "UNTEST";
  const showTestButton = props.useRecomendTest && !disabledTestStatus && props.contentsTestTrasition;
  const PC = () => {
    const overlayArea = () => {
      return (
        <>
          <Button.Group size="large" widths="2">
            <Button color="green" size="small" icon="play" content="視聴" onClick={props.contentsTrasition} />
            <Button
              color="blue"
              size="small"
              icon="pencil alternate"
              onClick={props.contentsTestTrasition}
              content={testStatus == "UNTEST" ? "テスト" : "再テスト"}
            />
          </Button.Group>
        </>
      );
    };
    const MainContent = () => {
      return (
        <div className="contents_item">
          <div className="left">
            <div className="thumbnail_wrapper">
              <Image src={props.image} className="thumbnail" />
              <div className="duration">
                <p>{getDurationString(props.playTime)}</p>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="info">
              <div className="item_header">
                <div>
                  <p className="title">{props.name}</p>
                  {props.themeName && (
                    <p className="theme_name">
                      {props.categoryName ? `${props.categoryName} / ${props.themeName}` : props.themeName}
                    </p>
                  )}
                </div>
                {props.useStatus && (
                  <div className="status">
                    <div>
                      <ContentsStatusLabel status={contentsStatus} size="mini" />
                    </div>
                    {!disabledTestStatus && (
                      <div>
                        <TestStatusLabel
                          status={testStatus}
                          size="mini"
                          testPassed={undefinedToBoolean(props.testPass, true)}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="description_area">
                <p className="description">{props.description}</p>
              </div>
            </div>
          </div>
        </div>
      );
    };

    return !showTestButton ? (
      <>
        <div className="pc-contents_item one_action" onClick={props.contentsTrasition}>
          <MainContent />
        </div>
        {undefinedToNumber(props.length, 0) - 1 !== props.index && <Divider />}
      </>
    ) : (
      <>
        <CustomOverlay overlayContent={overlayArea()}>
          <div className="pc-contents_item">
            <MainContent />
          </div>
        </CustomOverlay>
        {undefinedToNumber(props.length, 0) - 1 !== props.index && <Divider />}
      </>
    );
  };

  const SP = () => {
    const ButtonArea = () => {
      return (
        <div className="sp-contents-button_area">
          <Button.Group size="large" widths="2">
            <Button color="green" size="small" icon="play" content="視聴" onClick={props.contentsTrasition} />
            <Button
              color="blue"
              size="small"
              icon="pencil alternate"
              onClick={props.contentsTestTrasition}
              content={testStatus == "UNTEST" ? "テスト" : "再テスト"}
            />
          </Button.Group>
        </div>
      );
    };
    return (
      <>
        <div className="sp-contents_item_header">
          {props.customHeader && (
            <>
              <div className="item_custom_header">
                <p>{props.customHeader}</p>
              </div>
            </>
          )}
        </div>
        <div onClick={showTestButton ? undefined : props.contentsTrasition}>
          <Card fluid className={showTestButton ? "sp-contents_item_card" : "sp-contents_item_card one_action"}>
            <div className="sp-contents_item">
              <div className="item">
                <div className={`item_name ${props.useStatus && "item_status"}`}>
                  <div>
                    <p>{props.name}</p>
                    {props.themeName && (
                      <p className="theme_name">
                        {props.categoryName ? `${props.categoryName} / ${props.themeName}` : props.themeName}
                      </p>
                    )}
                  </div>
                  {props.useStatus && (
                    <div className="status">
                      <ContentsStatusLabel status={contentsStatus} size="mini" />
                      {contentsStatus !== "NEW" && !disabledTestStatus && (
                        <TestStatusLabel
                          status={testStatus}
                          size="mini"
                          testPassed={undefinedToBoolean(props.testPass, true)}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="item_body">
                  <div className="thumbnail_wrapper">
                    <div className="thumbnail">
                      <Image src={props.image} className="thumbnail_image" />
                      {props.playTime && (
                        <div className="duration">
                          <p>{getDurationString(props.playTime)}</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="info_description">
                    <p>{props.description}</p>
                  </div>
                </div>
                {showTestButton && <ButtonArea />}
              </div>
            </div>
          </Card>
        </div>
      </>
    );
  };
  if (props.deviceType == "PC") {
    return <PC />;
  } else {
    return <SP />;
  }
};

export const CustomThemeReportItem = (props: CustomThemeReportItemProps) => {
  const navigate = useNavigate();
  const accountOriginalFlg = props.categoryAccountOriginalId != undefined;
  const reportId = props.id;
  const { contextData: user } = useUserContext();

  const { callLoadAccountUnconfirmedReports } = useLoadAccountUnconfirmedReports();
  const { dispatch: badgeDispatch } = useBadgeDispatch();

  const updateBadgeInfo = async () => {
    const { isSuccess, result } = await callLoadAccountUnconfirmedReports(user.accountId);
    if (isSuccess) {
      badgeDispatch(setUnconfirmedReportCount(result));
    }
  };
  const PC = () => {
    const ButtonArea = (props: { reportStatus: reportStatus }) => {
      const { isUpdating: isReportStatusConfirmdUpdating, callUpdateReportStatusConfirmApi } =
        useUpdateReportStatusConfirmdApi();
      const { isUpdating: isReportStatusUnconfirmdUpdating, callUpdateReportStatusUnconfirmApi } =
        useUpdateReportStatusUnconfirmdApi();
      const [reportStatus, setReportStatus] = useState<reportStatus>(props.reportStatus);

      const updateConfirmStatus = async (updateStatus: reportStatus) => {
        if (updateStatus == "CONFIRMED") {
          const { isSuccess } = await callUpdateReportStatusConfirmApi(accountOriginalFlg, reportId);
          if (isSuccess) {
            setReportStatus(updateStatus);
            updateBadgeInfo();
          }
        } else {
          const { isSuccess } = await callUpdateReportStatusUnconfirmApi(accountOriginalFlg, reportId);
          if (isSuccess) {
            setReportStatus(updateStatus);
            updateBadgeInfo();
          }
        }
      };

      return reportStatus == "UNCONFIRMED" ? (
        <Button
          content="確認済にする"
          size="mini"
          icon="check"
          color="blue"
          loading={isReportStatusConfirmdUpdating}
          onClick={() => {
            updateConfirmStatus("CONFIRMED");
          }}
        />
      ) : (
        <Button
          content="未確認に戻す"
          size="mini"
          icon="check"
          color="grey"
          loading={isReportStatusUnconfirmdUpdating}
          onClick={() => {
            updateConfirmStatus("UNCONFIRMED");
          }}
        />
      );
    };

    return (
      <div className="custom_theme_report_item">
        <div className="theme_title">
          <p className="title_text">
            {props.themeName}
            <Icon
              className="link_icon"
              name="window restore outline"
              size="small"
              onClick={() => {
                navigate(
                  replaceIdKeyFromRoutePath(
                    {
                      categoryId: props.categoryId,
                      themeId: props.themeId,
                    },
                    ROUTE_PATH.THEME
                  )
                );
              }}
            />
          </p>
        </div>
        <div className="report">
          <p className="impressions_text">{props.report}</p>
        </div>
        <div className="action">
          <div className="action_confirme">
            <ButtonArea reportStatus={props.status} />
          </div>
        </div>
      </div>
    );
  };
  const SP = () => {
    // レポート詳細はモバイル表示なし
    return <></>;
  };
  if (props.deviceType == "PC") {
    return <PC />;
  } else {
    return <SP />;
  }
};

export const CustomLatestLogin = (props: { latestOperationTime: Date | null }) => {
  if (!props.latestOperationTime) {
    return <span>-</span>;
  }

  const beforeDays = calcDayAgo(props.latestOperationTime);

  return (
    <div className="latest_login">
      <Popup
        content={getDateTimeString(props.latestOperationTime, "short")}
        trigger={<span>{beforeDays > 0 ? (beforeDays >= 30 ? "30日以上前" : `${beforeDays}日前`) : "本日"}</span>}
      />
    </div>
  );
};

export const CustomTextWithPopup = (props: { text: string; limit: number }) => {
  if (undefinedToString(props.text, "").length <= props.limit) {
    return <span>{props.text}</span>;
  }
  return (
    <div>
      <Popup content={props.text} trigger={<span>{textOverflow(props.text, props.limit)}</span>} />
    </div>
  );
};

export const CustomCircleLabel = (props: { count: number }) => {
  return <Label content={props.count} color={props.count > 0 ? "orange" : "grey"} size="huge" circular />;
};

export const CustomLevelLabel = (props: { level: number }) => {
  const levelColor = getLevelColorClassName(props.level);
  return <Label className={`${levelColor} level_label`} content={`Lv.${props.level}`} />;
};

export const CustomPieChart = <T,>(props: {
  datas?: T[];
  colors?: string[];
  size?: number;
  labelType?: "value" | "percent";
  labelSuffix?: string;
}) => {
  const COLORS = props.colors || levelColorCodeArray;
  const size = props.size == undefined ? 200 : props.size;
  const labelType = props.labelType || "percent";
  const labelSuffix = props.labelSuffix || "";
  const data = props.datas || [
    {
      name: "Lv1",
      count: 3,
    },
    {
      name: "Lv2",
      count: 4,
    },
    {
      name: "Lv3",
      count: 5,
    },
    {
      name: "Lv4",
      count: 6,
    },
    {
      name: "Lv5",
      count: 3,
    },
    {
      name: "Lv6",
      count: 2,
    },
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    count,
  }: {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    percent: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    count: any;
  }) => {
    if (percent == 0) {
      return "";
    }
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={"middle"} dominantBaseline="central">
        {labelType == "percent"
          ? (percent * 100).toFixed(0) == "0"
            ? ""
            : `${(percent * 100).toFixed(0)}%`
          : `${count}${labelSuffix}`}
      </text>
    );
  };

  return (
    <PieChart width={size} height={size}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={size / 2 - 10}
        fill="#8884d8"
        dataKey="count"
        minAngle={15}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

export const CustomBarChart = <T,>(props: { datas: T[]; colors: string[] }) => {
  const colors = props.colors;
  const datas = props.datas;
  const defaultFontColor = "#5f5f5f";

  const CustomizedTick = (props: {
    index: number;
    x: number;
    y: number;
    payload: { value: string; width: number };
  }) => {
    const values = props.payload.value.split(",");
    return (
      <>
        {values.map((value, index) => {
          const color =
            index === 0 ? (colors.length > props.index ? colors[props.index] : defaultFontColor) : defaultFontColor;
          const yProps = props.y + 18 + index * 20;
          if (index === 0) {
            return (
              <text key={index} x={props.x} y={yProps} textAnchor="middle">
                <tspan className="bar_chart_label" fill={color}>
                  {value}
                </tspan>
              </text>
            );
          } else {
            return (
              <text key={index} x={props.x} y={yProps} textAnchor="middle">
                <tspan className="bar_chart_percent" fill={color}>
                  {value}
                </tspan>
              </text>
            );
          }
        })}
      </>
    );
  };

  return (
    <BarChart
      width={500}
      height={200}
      data={datas}
      barCategoryGap={25}
      margin={{ top: 20, right: 5, bottom: 25, left: 5 }}
    >
      <Bar dataKey="count">
        {datas.map((entry, index) => {
          return <Cell key={`cell-${index}`} fill={colors.length > index ? colors[index] : "#5f5f5f"} />;
        })}
        <LabelList dataKey="countLabel" fontSize="1.2rem" position="top" fill={defaultFontColor} />
      </Bar>
      <XAxis
        dataKey="name"
        axisLine={<Line stroke="#5f5f5f" strokeLinecap="square" strokeOpacity="0.5" strokeWidth="0.5" />}
        tick={CustomizedTick}
        tickLine={false}
      />
    </BarChart>
  );
};

export const CustomOverlay = (props: childrenProps & { overlayContent: ReactNode }) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const showHover = () => {
    setIsHover(true);
  };
  const hiddenHover = () => {
    setIsHover(false);
  };

  return (
    <Dimmer.Dimmable
      className="custom_overlay_dimmed"
      dimmed={isHover}
      onMouseEnter={showHover}
      onMouseLeave={hiddenHover}
      size="medium"
    >
      {props.children}
      <Dimmer className="custom_overlay" active={isHover}>
        <div className="hover_menu">{props.overlayContent}</div>
      </Dimmer>
    </Dimmer.Dimmable>
  );
};

export const CustomHeaderWithAccountInfomation = (props: customHeaderWithAccountInfomationProps) => {
  return (
    <Header as="h3" className="header_with_account">
      <>
        {props.headerText}
        <span className="header_account_name">
          （{props.accountName} / {props.accountId}）
        </span>
      </>
    </Header>
  );
};

export const CustomHeaderWithUserId = (props: customHeaderWithUserIdProps) => {
  return (
    <Header as="h3" className="header_with_user_id">
      <>
        {props.headerText}
        <span className="header_user_id">（{props.userId}）</span>
      </>
    </Header>
  );
};

export const CustomTestItem = (props: customTestItemProps) => {
  const navigate = useNavigate();
  const getChoiceLabelClassName = (
    answered: boolean,
    answer: selectedAnswerType[],
    questionItem: apiLoadTestItem,
    answerItem: apiLoadTestTemplateDtl
  ) => {
    if (answered) {
      return `${
        props.isTestedCheked(questionItem, questionItem.id, answerItem.id) ? "list_checked" : "list_unchecked"
      } ${questionItem.correct ? "correct" : "incorrect"}`;
    }
    return props.isCheked(answer, questionItem.id, answerItem.id) ? "list_checked" : "list_unchecked";
  };

  const replaceWrongHighlight = (text: string) => {
    return text.replace(/誤っている/g, "<span class='wrong_highlight'>誤っている</span>");
  };

  const QuestionContents = (props: { theme?: apiLoadTheme; contents?: apiLoadContents }) => {
    if (props.theme && props.contents) {
      if (numberToBoolean(props.contents.releaseFlg)) {
        return (
          <div className="question_contents">
            <div className="question_contents_title">
              <div>
                <span className="label">出題コンテンツ</span>
              </div>
              <div>
                <span className="contents_name">{props.contents.name}</span>
              </div>
            </div>
            <div className="question_contents_button">
              <Button
                content="視聴する"
                color="green"
                size="small"
                onClick={() => {
                  navigate(
                    replaceIdKeyFromRoutePath(
                      {
                        contentsId: props.contents?.id,
                      },
                      ROUTE_PATH.CONTENTS
                    )
                  );
                }}
              />
            </div>
          </div>
        );
      } else {
        return (
          <div className="question_contents_unreleased">
            <div className="question_contents_title">
              <div>
                <span className="label">出題コンテンツ</span>
              </div>
              <div>
                <span className="contents_name">{props.contents.name}</span>
              </div>
              <div>
                <span className="unrelease_info">（このコンテンツは非公開になりました）</span>
              </div>
            </div>
          </div>
        );
      }
    }
    return <></>;
  };
  return (
    <div
      className={`question ${props.mode === "PC" ? "pc-question" : "sp-question"}`}
      key={`question_${props.questionItemIndex}`}
    >
      <div className={`question_title_header ${props.unanswered ? "unanswered" : ""}`}>
        <div className="badge">
          <p>
            問題<span>{props.questionItem.questionNo}</span>
          </p>
        </div>
        <div className="unanswered_label">
          {props.unanswered && (
            <p>
              <IoWarning className="unanswered_icon" />
              未回答です
            </p>
          )}
        </div>
        <div className="correct_icon">
          {props.answered &&
            (props.questionItem.correct ? (
              <div className="correct">
                <Image src={correctIcon} />
                <p>正解</p>
              </div>
            ) : (
              <div className="incorrect">
                <Image src={incorrectIcon} />
                <p>不正解</p>
              </div>
            ))}
        </div>
      </div>
      <p
        className={`question_title ${props.unanswered ? "unanswered" : ""}`}
        dangerouslySetInnerHTML={{ __html: `${replaceWrongHighlight(props.questionItem.question)}` }}
      />
      {props.questionItem.questionType === "TEXT_IMAGE" && (
        <Image src={props.questionItem.questionImage} size="small" centered />
      )}
      <List className="answer">
        {props.questionItem.templateDtls.map((answerItem, answerItemIndex) => (
          <List.Item
            key={`question_${props.questionItemIndex}_${answerItemIndex}`}
            className={getChoiceLabelClassName(props.answered, props.answer, props.questionItem, answerItem)}
          >
            {props.answered ? (
              <>
                {props.questionItem.correctAnswerType === "MULTI" && (
                  <>
                    <Checkbox
                      label={
                        <>
                          {props.questionItem.answerTemplateType !== "TEXT" && (
                            <div className="choice_image">
                              <Image src={answerItem.answerImage} size="small" />
                            </div>
                          )}
                          <label>{answerItem.answer}</label>
                        </>
                      }
                      value={answerItem.id}
                      onChange={(e, data) => {
                        props.multiInputReceive(data);
                      }}
                      disabled={props.answered}
                      code={{
                        testTemplateId: answerItem.testTemplateId,
                        testTemplateDtlId: answerItem.id,
                        questionNo: props.questionItem.questionNo,
                      }}
                      checked={props.isTestedCheked(props.questionItem, props.questionItem.id, answerItem.id)}
                    />
                  </>
                )}
                {props.questionItem.correctAnswerType === "SIMPLE" && (
                  <>
                    <Radio
                      label={
                        <>
                          {props.questionItem.answerTemplateType !== "TEXT" && (
                            <div className="choice_image">
                              <Image src={answerItem.answerImage} size="small" />
                            </div>
                          )}
                          <label>{answerItem.answer}</label>
                        </>
                      }
                      value={answerItem.id}
                      code={{
                        testTemplateId: answerItem.testTemplateId,
                        testTemplateDtlId: answerItem.id,
                        questionNo: props.questionItem.questionNo,
                      }}
                      disabled={props.answered}
                      checked={props.isTestedCheked(props.questionItem, props.questionItem.id, answerItem.id)}
                      onChange={(e, data) => {
                        props.simpleInputReceive(data);
                      }}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {props.questionItem.correctAnswerType === "MULTI" && (
                  <>
                    <Checkbox
                      label={
                        <>
                          {props.questionItem.answerTemplateType !== "TEXT" && (
                            <div className="choice_image">
                              <Image src={answerItem.answerImage} size="small" />
                            </div>
                          )}
                          <label>{answerItem.answer}</label>
                        </>
                      }
                      value={answerItem.id}
                      onChange={(e, data) => {
                        props.multiInputReceive(data);
                      }}
                      code={{
                        testTemplateId: answerItem.testTemplateId,
                        testTemplateDtlId: answerItem.id,
                        questionNo: props.questionItem.questionNo,
                      }}
                      checked={props.isCheked(props.answer, props.questionItem.id, answerItem.id)}
                    />
                  </>
                )}
                {props.questionItem.correctAnswerType === "SIMPLE" && (
                  <>
                    <Radio
                      label={
                        <>
                          {props.questionItem.answerTemplateType !== "TEXT" && (
                            <div className="choice_image">
                              <Image src={answerItem.answerImage} size="small" />
                            </div>
                          )}
                          <label>{answerItem.answer}</label>
                        </>
                      }
                      value={answerItem.id}
                      code={{
                        testTemplateId: answerItem.testTemplateId,
                        testTemplateDtlId: answerItem.id,
                        questionNo: props.questionItem.questionNo,
                      }}
                      disabled={props.answered}
                      checked={props.isCheked(props.answer, props.questionItem.id, answerItem.id)}
                      onChange={(e, data) => {
                        props.simpleInputReceive(data);
                      }}
                    />
                  </>
                )}
              </>
            )}
          </List.Item>
        ))}
      </List>
      {props.answered &&
        (props.questionItem.correct ? (
          <Message className="ui_message correct_message">
            {props.questionItem.correctAnswerDescription}
            <QuestionContents theme={props.questionItem.theme} contents={props.questionItem.contents} />
          </Message>
        ) : (
          <Message className="ui_message incorrect_message">
            {props.questionItem.incorrectAnswerDescription}
            <QuestionContents theme={props.questionItem.theme} contents={props.questionItem.contents} />
          </Message>
        ))}
    </div>
  );
};

export const CustomNotFoundPageMessage = (props: customNotFoundPageMessage) => {
  const navigate = useNavigate();
  return (
    <div className={`notfound_message ${props.deviceType === "SP" && "sp-notfound_message"}`}>
      <p className="title">該当のページが見つかりませんでした。</p>
      <p className="body">すでに削除されたページの可能性があります。</p>
      {!props.hideButton && (
        <Button
          color="blue"
          content="マイページへ"
          size="small"
          onClick={() => {
            navigate(`${replaceIdKeyFromRoutePath({}, ROUTE_PATH.MYPAGE)}`);
          }}
        />
      )}
    </div>
  );
};

export const CustomSimpleContentsItem = (props: commonContentsItemProps) => {
  const navigate = useNavigate();
  const pageTransition = (accountOriginalFlg: boolean, ids: idType | undefined, packageId?: number) => {
    if (ids !== undefined) {
      if (accountOriginalFlg) {
        navigate(replaceIdKeyFromRoutePath(ids, ROUTE_PATH.CONTENTS_ORIGINAL));
      } else {
        if (packageId === undefined || packageId === 0) {
          navigate(replaceIdKeyFromRoutePath(ids, ROUTE_PATH.CONTENTS));
        } else {
          navigate(replaceIdKeyFromRoutePath({ ...ids, packageId: packageId }, ROUTE_PATH.PACKAGE_CONTENTS));
        }
      }
    }
  };
  const progressRate = (() => {
    if (props.playTime && props.replayTime) {
      const rate = Math.round((props.replayTime / props.playTime) * 100);
      if (rate > 100) {
        return 100;
      }
      return Math.round((props.replayTime / props.playTime) * 100);
    }
    return 0;
  })();
  if (props.deviceType === "PC") {
    return (
      <div
        className={`pc-simple_contents_item ${props.releaseFlg === 0 ? "closed_item" : "released_item"}`}
        onClick={() => {
          if (props.releaseFlg === 1) {
            pageTransition(undefinedToBoolean(props.accountOriginalFlg, false), props.ids, props.packageId);
          }
        }}
      >
        <div className="item_card">
          <div className="thumbnail_wrapper">
            <div className="thumbnail">
              <Image src={props.image} wrapped ui={false} />
            </div>
            <div className="duration">
              <p>{getDurationString(props.playTime)}</p>
            </div>
            {props.progress && (
              <div className="progress">
                <div
                  className="bar"
                  style={props.contentsStatus === "VIEWED" ? { width: "100%" } : { width: String(progressRate) + "%" }}
                ></div>
                {progressRate > 3 && (
                  <div
                    className="progress_point"
                    style={
                      props.contentsStatus === "VIEWED" ? { display: "none" } : { left: String(progressRate - 2) + "%" }
                    }
                  ></div>
                )}
              </div>
            )}
          </div>
          <div className="item_title">
            {props.packageId !== undefined && props.packageId !== 0 && <PackageViewLabel />}
            <p className="item_header">{props.name}</p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className={`sp-simple_contents_item ${props.releaseFlg === 0 && "closed_item"}`}
      onClick={() => {
        if (props.releaseFlg === 1) {
          pageTransition(undefinedToBoolean(props.accountOriginalFlg, false), props.ids, props.packageId);
        }
      }}
    >
      <div className="left">
        <div className="thumbnail_wrapper">
          <Image src={props.image} className="thumbnail" />
          <div className="duration">
            <p>{getDurationString(props.playTime)}</p>
          </div>
          {props.progress && (
            <div className="progress">
              <div
                className="bar"
                style={props.contentsStatus === "VIEWED" ? { width: "100%" } : { width: String(progressRate) + "%" }}
              ></div>
              {progressRate > 3 && (
                <div
                  className="progress_point"
                  style={
                    props.contentsStatus === "VIEWED" ? { display: "none" } : { left: String(progressRate - 2) + "%" }
                  }
                ></div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="right">
        {props.packageId !== undefined && props.packageId !== 0 && <PackageViewLabel />}
        <p className="item_header">{props.name}</p>
      </div>
    </div>
  );
};

export const CustomSimpleLayoutGroupingContentsList = (props: customSimpleLayoutGrouingContentsListProps) => {
  const initialTheme = (() => {
    if (props.page?.themes === undefined) return [];
    return props.page?.themes.filter(
      (item: apiLoadMypageAccountOriginalTheme | apiLoadMypageFreeContentsAndTutorialTheme) =>
        props.selectedCategory === item.theme.categoryId
    );
  })();

  const filteringTheme = (id: number) => {
    if (props.page !== undefined) {
      return props.page?.themes.filter(
        (item: apiLoadMypageAccountOriginalTheme | apiLoadMypageFreeContentsAndTutorialTheme) =>
          id === item.theme.categoryId
      );
    }
    return [];
  };
  const SimpleLayoutGroupingContentsListTheme = (props: customSimpleLayoutGrouingThemeProps) => {
    const [active, setActive] = useState<boolean>(true);
    return (
      <Accordion className="simple_layout_accordion">
        <Accordion.Title active={active} index={0} onClick={() => setActive(!active)}>
          <div>
            <Icon size="small" name="dropdown" />
          </div>
          <div>
            <p className="simple_layout_title">{props.themeName}</p>
          </div>
        </Accordion.Title>
        <Accordion.Content active={active}>
          {props.contentsList.map(
            (contentsItem: apiLoadMypageAccountOriginalContents | apiLoadMypageFreeContentsItems) => (
              <>
                <CustomSimpleContentsItem
                  key={`${props.mode}SimpleLayoutGroupingContents_${contentsItem.contents.id}`}
                  image={contentsItem.contents.image}
                  name={contentsItem.contents.name}
                  description={contentsItem.contents.description}
                  id={contentsItem.contents.id}
                  testStatus={"TESTED"}
                  playTime={contentsItem.contents.playtime}
                  progress={true}
                  replayTime={contentsItem.historyContentsLatest.replayTime}
                  deviceType={props.deviceType}
                  contentsStatus={contentsItem.historyContentsLatest.status}
                  ids={{
                    categoryId: contentsItem.contents.categoryId,
                    themeId: contentsItem.contents.themeId,
                    contentsId: contentsItem.contents.id,
                  }}
                  requiredTest={numberToBoolean(contentsItem.contents.requiredTest)}
                  accountOriginalFlg={contentsItem.contents.accountOriginalFlg}
                  releaseFlg={1}
                />
              </>
            )
          )}
        </Accordion.Content>
      </Accordion>
    );
  };
  if (props.page === undefined) return <></>;
  return (
    <Tab.Pane loading={props.loading} attached={false}>
      <div className={`${props.deviceType.toLowerCase()}-simple_layount_grouping_contents_list`}>
        {initialTheme.length !== 0 && (
          <Menu secondary className="simple_layount_category_menu" size="small">
            {props.page?.categories.map((category) => (
              <Menu.Item
                key={`simple_layount_category_${category.id}`}
                name={category.name}
                active={props.selectedCategory === category.id}
                onClick={() => props.setSelectedCategory(Number(category.id))}
              />
            ))}
          </Menu>
        )}
        <div className="normal_padding">
          <div
            className={initialTheme.length === 0 ? "simple_layount_grouping_contents_list no_data" : "account_original"}
          >
            {initialTheme.length === 0 && (
              <p className="information_nodata">
                {props.mode === "accountOriginal" && "まだ施設オリジナルコンテンツはありません。"}
                {props.mode === "freeContents" && "まだフリーコンテンツはありません。"}
                {props.mode === "tutorial" && "まだチュートリアルはありません。"}
              </p>
            )}
            {filteringTheme(props.selectedCategory ?? 0).map((themeItem) => (
              <SimpleLayoutGroupingContentsListTheme
                key={`${props.mode}Theme_${themeItem.theme.id}`}
                mode={props.mode}
                themeId={themeItem.theme.id}
                themeName={themeItem.theme.name}
                contentsList={themeItem.contentsList}
                deviceType={props.deviceType}
              />
            ))}
          </div>
        </div>
      </div>
    </Tab.Pane>
  );
};

export const CustomCurriculumThemeContentsItem = (props: { contentsItem: apiLoadContents }) => {
  const navigate = useNavigate();
  const pageTransitionContents = (contentsId: number, accountOriginalFlg: boolean) => {
    navigate(getContentPagePath(contentsId, accountOriginalFlg));
  };
  const ContentsStatus = (props: {
    historyContentsStatus: contentsStatus;
    requiredTest: boolean;
    testCount: number;
    testPass: boolean;
  }) => {
    // 未視聴→未視聴
    // 視聴中でtestCountが0→視聴中
    // 視聴中 or 視聴済でtestCountが1以上→testPassのステータス
    // 視聴済でtestCountが0→未テスト
    if (props.historyContentsStatus === "NEW") {
      return <ContentsStatusLabel circular={true} size="mini" status="NEW" />;
    }
    if (props.historyContentsStatus === "VIEWING") {
      if (props.testCount === 0) {
        return <ContentsStatusLabel circular={true} size="mini" status="VIEWING" />;
      } else {
        return <ContentsPassLabel status={props.testPass ? "PASS" : "FAILURE"} />;
      }
    }
    if (props.historyContentsStatus === "VIEWED") {
      if (props.requiredTest) {
        if (props.testCount === 0) {
          return <ContentsPassLabel status="UNTESTED" />;
        } else {
          return <ContentsPassLabel status={props.testPass ? "PASS" : "FAILURE"} />;
        }
      } else {
        return <ContentsStatusLabel circular={true} size="mini" status="VIEWED" />;
      }
    }
    return <></>;
  };
  return (
    <div className="item">
      <div className="status">
        <ContentsStatus
          historyContentsStatus={undefinedToString(props.contentsItem.historyContentsStatus, "NEW") as contentsStatus}
          requiredTest={numberToBoolean(props.contentsItem.requiredTest)}
          testCount={undefinedToNumber(props.contentsItem.testCount, 0)}
          testPass={undefinedToBoolean(props.contentsItem.testPass, false)}
        />
      </div>
      <div className="title" onClick={() => pageTransitionContents(props.contentsItem.id, false)}>
        {props.contentsItem.name}
      </div>
    </div>
  );
};

export const CustomCurriculumTheme = (props: {
  deviceType: deviceType;
  themeItem: apiLoadMypageLegalCurriculumTheme;
}) => {
  const navigate = useNavigate();
  const pageTransitionTheme = (theme: apiTheme | apiLoadMypageLegalCurriculumTheme | undefined) => {
    if (!theme) {
      return;
    }
    navigate(getThemePagePath(theme.categoryId, theme.id));
  };
  return (
    <div className={props.deviceType === "PC" ? "pc-curriculum_theme" : "sp-curriculum_theme"}>
      <div className="theme_information">
        <div className="information">
          <div className="status">
            <ThemeStatusLabel status={props.themeItem.studyStatus} size="mini" />
            <ReportStatusLabel reportFinished={props.themeItem.reportFinished} size="mini" />
          </div>
          <div className="title" onClick={() => pageTransitionTheme(props.themeItem)}>
            {props.themeItem.name}
          </div>
        </div>
        <div className="progress">
          <div className="progress_label">
            <span className="denominator">{props.themeItem.contentsPassCount}</span>/
            <span className="numerator">{props.themeItem.contentsCount}</span>
          </div>
          <div>
            <Label className="progress_title" circular content="進捗" size="mini" color="blue" />
          </div>
        </div>
      </div>
      <div className="contents_items">
        {props.themeItem.contents.map((item) => (
          <CustomCurriculumThemeContentsItem key={`curriculumThemeContents_${item.id}`} contentsItem={item} />
        ))}
      </div>
      <div className="report">
        {/* <p className="report_label">レポート</p> */}
        <LegalReportForm
          deviceType={props.deviceType}
          categoryId={props.themeItem.categoryId}
          theme={props.themeItem}
          mode="MYPAGE"
        />
        {/* <p>{props.themeItem.report}</p> */}
      </div>
    </div>
  );
};

export const CustomTransparentDimmer = () => {
  return <Dimmer active inverted className="transparent_dimmer" />;
};

export const CustomCareerItem = (props: customCareerItemProps) => {
  const navigate = useNavigate();
  const pageTransition = (careerType: careerType, level: number) => {
    navigate(replaceIdKeyFromRoutePath({ careerType: careerType.toLowerCase(), level: level }, ROUTE_PATH.CAREER));
  };
  const CompletionMedal = () => {
    return (
      <div className="completion_medal">
        <Image className="medal_icon" src={completionMedal} />
        <p>修了済</p>
      </div>
    );
  };
  return (
    <div
      className={`custom_category_item ${
        props.deviceType === "PC" ? "pc-custom_category_item" : "sp-custom_category_item"
      }`}
      onClick={() => pageTransition(props.careerType, props.level)}
    >
      <div className="thumbnail">
        {props.pass && <CompletionMedal />}
        <div className="thumbnail_wrapper">
          <Image src={props.image} className="thumbnail_image" />
        </div>
      </div>
      <div className="info">
        <div>
          <p className="title">{props.name}</p>
        </div>
        <div>
          <p className="description">{props.description}</p>
        </div>
      </div>
    </div>
  );
};

export const HintIcon = (props: { text: string }) => {
  if (getLength(props.text) > 0) {
    return (
      <Popup
        content={props.text}
        className="hint_popup"
        trigger={<Icon name="question circle outline" className="hint_popup_icon" />}
      />
    );
  } else {
    return <></>;
  }
};

export const TextWithHintIcon = (props: { text: string; description: string | ReactNode; textClassName?: string }) => {
  if (getLength(props.text) > 0) {
    return (
      <Popup
        content={props.description}
        className="text_with_hint_popup"
        trigger={
          <div>
            {<span className={props.textClassName ?? ""}>{props.text}</span>}
            <Icon name="question circle outline" className="hint_popup_icon" />
          </div>
        }
      />
    );
  } else {
    return <></>;
  }
};

export const TestCustomDropdown = (props: { date: Date; setDate: React.Dispatch<React.SetStateAction<Date>> }) => {
  const optios = [
    { key: "202304", text: "2023年04月", value: "202304" },
    { key: "202305", text: "2023年05月", value: "202305" },
    { key: "202306", text: "2023年06月", value: "202306" },
    { key: "202307", text: "2023年07月", value: "202307" },
    { key: "202308", text: "2023年08月", value: "202308" },
    { key: "202309", text: "2023年09月", value: "202309" },
    { key: "202310", text: "2023年10月", value: "202310" },
    { key: "202311", text: "2023年11月", value: "202311" },
    { key: "202312", text: "2023年12月", value: "202312" },
    { key: "202401", text: "2024年01月", value: "202401" },
    { key: "202402", text: "2024年02月", value: "202402" },
    { key: "202403", text: "2024年03月", value: "202403" },
  ];

  const changeValue = (data: DropdownProps) => {
    if (data.value && typeof data.value === "string") {
      const year = data.value.slice(0, 4);
      const month = data.value.slice(4, 6);
      const date = new Date(Number(year), Number(month) - 1);
      props.setDate(date);
    }
  };

  const getValue = (selectedDate: Date) => {
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth() + 1;
    return `${year}${month < 10 ? `0${month}` : month}`;
  };
  return (
    <div style={{ backgroundColor: "#dadada", padding: "1rem" }}>
      <p style={{ margin: "0", fontSize: "1rem" }}>テスト用</p>
      <Dropdown
        className="test_dropdown"
        style={{ margin: "1rem 0 1rem 0" }}
        selection
        options={optios}
        disabled={false}
        value={getValue(props.date)}
        onChange={(e, data) => changeValue(data)}
      />
    </div>
  );
};

const HintTextIcon = (props: { text: string }) => {
  if (getLength(props.text) > 0) {
    return (
      <Popup
        content={props.text}
        className="input_hint_popup"
        trigger={
          <div className="input_hint_popup_icon_background">
            <Icon className="input_hint_popup_icon" name="info" size="small" />
          </div>
        }
      />
    );
  } else {
    return <></>;
  }
};

export const LevelStars = (props: { level: number }) => {
  return (
    <div className="level_stars">
      <div className="level">
        <div>Lv.{props.level}</div>
      </div>
      <div className="stars">
        <Icon name="star" size="small" color="yellow" />
        <Icon name="star" size="small" color="yellow" disabled={props.level < 2} />
        <Icon name="star" size="small" color="yellow" disabled={props.level < 3} />
        <Icon name="star" size="small" color="yellow" disabled={props.level < 4} />
        <Icon name="star" size="small" color="yellow" disabled={props.level < 5} />
      </div>
    </div>
  );
};

export const InquiryBoxInquiryStatusLabelWithBadge = (props: { status: inquiryStatus; badgeCount: number }) => {
  switch (props.status) {
    case "NEW":
      return (
        <div className="inquiry_status_label_with_badge status_red">
          <div className="dot" />
          <p className="status_name">未対応</p>
          <div className="badge">
            <p className="badge_count">{props.badgeCount}</p>
          </div>
        </div>
      );
    case "NOT_REPLY":
      return (
        <div className="inquiry_status_label_with_badge status_red">
          <div className="dot" />
          <p className="status_name">返答あり</p>
          <div className="badge">
            <p className="badge_count">{props.badgeCount}</p>
          </div>
        </div>
      );
    case "TEMP_REPLIED":
      return (
        <div className="inquiry_status_label_with_badge status_red">
          <div className="dot" />
          <p className="status_name">一次返答済み</p>
          <div className="badge">
            <p className="badge_count">{props.badgeCount}</p>
          </div>
        </div>
      );
    case "REPLIED":
      return (
        <div className="inquiry_status_label_with_badge status_green">
          <div className="dot" />
          <p className="status_name">返答済</p>
          <div className="badge">
            <p className="badge_count">{props.badgeCount}</p>
          </div>
        </div>
      );
    default:
      break;
  }
  return <></>;
};

export const InquiryBoxInquiryStatusLabel = (props: { isDmAdmin: boolean; status: inquiryStatus }) => {
  if (props.isDmAdmin) {
    switch (props.status) {
      case "NEW":
        return (
          <div className="inquiry_status_label status_red">
            <p className="status_name">未対応</p>
          </div>
        );
      case "NOT_REPLY":
        return (
          <div className="inquiry_status_label status_red">
            <p className="status_name">返答あり</p>
          </div>
        );
      case "TEMP_REPLIED":
        return (
          <div className="inquiry_status_label status_red">
            <p className="status_name">一次返答済み</p>
          </div>
        );
      case "REPLIED":
        return (
          <div className="inquiry_status_label status_green">
            <p className="status_name">返答済</p>
          </div>
        );
      default:
        break;
    }
  } else {
    switch (props.status) {
      case "NEW":
        return (
          <div className="inquiry_status_label status_red">
            <p className="status_name">返答待ち</p>
          </div>
        );
      case "NOT_REPLY":
        return (
          <div className="inquiry_status_label status_red">
            <p className="status_name">返答待ち</p>
          </div>
        );
      case "TEMP_REPLIED":
        return (
          <div className="inquiry_status_label status_green">
            <p className="status_name">返答あり</p>
          </div>
        );
      case "REPLIED":
        return (
          <div className="inquiry_status_label status_green">
            <p className="status_name">返答あり</p>
          </div>
        );
      default:
        break;
    }
  }
  return <></>;
};

export const InquiryBoxInquiryStatusUserLabel = (props: { status: inquiryStatus; solved: boolean }) => {
  if (props.solved) {
    return (
      <div className="inquiry_status_label status_gray_disabled">
        <p className="status_name">解決済み</p>
      </div>
    );
  }
  switch (props.status) {
    case "NEW":
      return (
        <div className="inquiry_status_label status_gray">
          <p className="status_name">返答待ち</p>
        </div>
      );
    case "NOT_REPLY":
      return (
        <div className="inquiry_status_label status_gray">
          <p className="status_name">返答待ち</p>
        </div>
      );
    case "TEMP_REPLIED":
      return (
        <div className="inquiry_status_label status_blue">
          <p className="status_name">返答あり</p>
        </div>
      );
    case "REPLIED":
      return (
        <p className="inquiry_status_label status_blue">
          <p className="status_name">返答あり</p>
        </p>
      );
    default:
      break;
  }
  return <></>;
};

export const PackageStatusLabel = (props: { workFlg: boolean; releaseFlg: boolean; mini?: boolean }) => {
  return (
    <div
      className={`custom_package_management_item_status ${
        props.workFlg ? "draft" : props.releaseFlg ? "release" : "close"
      } ${props.mini ? "mini" : ""}`}
    >
      {props.workFlg ? <p>下書き</p> : <p>{props.releaseFlg ? "公開" : "非公開"}</p>}
    </div>
  );
};

export const PackageViewLabel = () => {
  return (
    <div className="package_view_label">
      <Image src={menuPackageManagement} className="package_view_icon" />
      <p>パッケージからの閲覧</p>
    </div>
  );
};
